import React, { useEffect, useState } from "react";
import { actionGetRptGrafLogPortico } from "../../../features/relatorios/rptGrafPortico/slices";
//import { actionGetGradeBySetor } from "../../../features/"
import factoryFields from "../../../helpers/factory-fields";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Button from "../../Button";
import Input from "../../Inputs";
import Select from "../../Select";
import FilterSetorMonitora from "../../FilterSetoresMonitora";

const initialState = {
    idSetor: '',//valor padrão
    reportType: '',
    dataIni: '',
    dataFim: ''
} as any;

const RptGrafLogPortico = () => {
  const [state, setState] = useState(initialState);
  const dispatch = useAppDispatch();
  const { getRptGrafLogPortico } = useAppSelector((state) => state);

  // useEffect(() => {
  //   dispatch(actionGetRptHistoricosDispositivos());
  // }, []);
    const getSetorSelected = (filter) => {
        const selectedFilter = filter !== null ? filter : '';
        console.log(selectedFilter)
        setState({ ...state, idSetor: selectedFilter })
    }

  const handlerChange = (e: any) => {
    const { name, value } = e.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
    console.log(state.dataIni, state.dataFim)
  };

    const handlerReport = () => {
        console.log(state.intervalo)
        dispatch(actionGetRptGrafLogPortico(state))
    }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 co-sm-12">
          <div className="mb-20">
            <Select
              size="sm"
              label="Formato do arquivo"
              options={factoryFields.typesReport}
              onChange={handlerChange}
              name="reportType"
            />
            <FilterSetorMonitora cb={getSetorSelected} />
            <Input
              type="datetime-local"
              size="sm"
              label="Data Início"
              name="dataIni"
              onChange={handlerChange}
            />
            <Input
              type="datetime-local"
              size="sm"
              label="Data Fim"
              name="dataFim"
              onChange={handlerChange}
            />
          </div>
          <Button size="sm" onClick={handlerReport} loading={getRptGrafLogPortico.status === "loading"}>
            Baixar Relatório
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RptGrafLogPortico