/* eslint-disable react/style-prop-object */
import React from "react";
import "./_styles.scss";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import BaseLayout from "../../components/Layout";
import FilterSetorMonitora from "../../components/FilterSetoresMonitora";
import { actionGetPontosMonitor } from "../../features/PontosMonitor/slices";
import Select from "../../components/Select";
import Input from "../../components/Inputs";
import Button from "../../components/Button";
import Modulo from "../../assets/moduloCanalInspecao.png";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getUser } from "../../helpers/user";
import Progress from "../../components/Progress";

const initialState = {
    //visible: false,
    //filter: null,
    listItensSetor: [],
} as any;

let responseSocket;

const contador = {
    num: 0,
}

const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SOCKET}/hubPtosMonitor`, {
        accessTokenFactory: () => getUser().token,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

const Actions = ({ handleCancelClick }) => {
    return (
        <div style={{ display: 'flex' }}>
            <Button size="sm" variant="danger" onClick={handleCancelClick}>
                Cancelar
            </Button>
        </div>
    );
};

const PageModulosOnline = () => {    

    //const [inputsDisabled, setInputsDisabled] = React.useState(true);
    //const [showCancelButton, setShowCancelButton] = React.useState(false);

    const dispatch = useAppDispatch();    

    const { getPontosMonitor } = useAppSelector((state) => state)
            
    const [state, setState] = React.useState(initialState)
    
    const createList = (data) => {
        //if (!data) return;

        //const filtered = data.filter(({ id }) => id === state.filter);
        const listItensSetor = data;

        setState({
            ...state,
            listItensSetor,
        });
    };

    React.useEffect(() => {
        //testar dispatch
        dispatch(actionGetPontosMonitor())
        connection.start().then(() => console.log('connection started aqui'));
    }, []);

    React.useEffect(() => {

        connection.on('SocketPtosMonitor', function (data) {
            responseSocket = data
            contador.num++
            if (contador.num > 1000) {
                contador.num = 1
            }
            //console.log(contador)
            createList(responseSocket.setores)            
        });

        if (responseSocket) {
            console.log(responseSocket.setores)
            createList(responseSocket.setores)
            console.log("opcao1")
        } else {
            console.log(getPontosMonitor.data)
            createList(getPontosMonitor.data.setores)
            console.log("opcao2")
        }
        
    }, [getPontosMonitor.data, state.filter, contador.num]);

    //const visibilidade = () => {
    //    setState({ ...state, visible:true });
    //}

    //const handleUpdateClick = () => {
    //    setState({ ...state, visible: true });
    //    setInputsDisabled(false);
    //    setShowCancelButton(true);
    //    if (connection) {
    //        connection.stop()
    //            .then(() => console.log('Socket desconectado'))
    //            .catch((err) => console.error('Erro ao desconectar o socket', err));
    //    }
    //}

    //const handleCancelClick = () => {
    //    //dispatch(actionGetGradeBySetor(state.filter));
    //    setState({ ...state, visible: false });
    //    setInputsDisabled(true);
    //    setShowCancelButton(false);
    //    connection.start().then(() => console.log('connection started novamente'));
    //}
    //console.log("state,ListItensSetor", state.listItensSetor)
    return (
        <BaseLayout>
            {/*<div className="container" style={{marginBottom: '20px'}}>*/}
            {/*    <div className="row headerPage">*/}
            {/*        <div className="col-lg-4 col-md-6 co-sm-12 mb-20">*/}
            {/*            <FilterSetorMonitora cb={getSetorSelected} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            
            {/*<div className="list-detahamento container col-12">*/}
            {/*    {state.listItensSetor ? (*/}
            {/*        state.listItensSetor.length > 0 ? (*/}
            {/*            (() => {*/}
            {/*                //console.log(state.listItensSetor.map(setor => setor.listModuloRxPrt), "estou aquiiii");*/}

            {/*                const allModules = state.listItensSetor.flatMap((setor) => setor.listModuloRxPrt);*/}

            {/*                return (*/}
            {/*                    //<div className="contentTabela">*/}
            {/*                    //    <div className="tabelaHeader">*/}
            {/*                    //        <h5 className="tabelaColuna">Canal de Inspeção</h5>*/}
            {/*                    //        <h5 className="tabelaColuna">Status</h5>*/}
            {/*                    //        <h5 className="tabelaColuna">Último Post</h5>*/}
            {/*                    //    </div>*/}
            {/*                    //    <div>*/}
            {/*                    //        {allModules.map((modulo, index) => {*/}
            {/*                    //            // Desestrutura os valores de monRaiox e monPortico*/}
            {/*                    //            const { monRaiox, monPortico } = modulo;*/}
            {/*                    //            console.log(modulo)*/}

            {/*                    //            let status;*/}
            {/*                    //            switch (monRaiox.online) {*/}
            {/*                    //                case true: status = "Online";*/}
            {/*                    //                    break;*/}
            {/*                    //                case false: status = "Offline";*/}
            {/*                    //                    break;*/}
            {/*                    //            }*/}

            {/*                    //            // Define o nome do canal de inspeção com base em monRaiox.idSetor*/}
            {/*                    //            let nomeCanalInspecao;*/}
            {/*                    //            switch (monRaiox.idSetor) {*/}
            {/*                    //                case 4:*/}
            {/*                    //                    nomeCanalInspecao = "Doméstico 1";*/}
            {/*                    //                    break;*/}
            {/*                    //                case 5:*/}
            {/*                    //                    nomeCanalInspecao = "Doméstico 2";*/}
            {/*                    //                    break;*/}
            {/*                    //                case 6:*/}
            {/*                    //                    nomeCanalInspecao = "Internacional 2";*/}
            {/*                    //                    break;*/}
            {/*                    //                case 7:*/}
            {/*                    //                    nomeCanalInspecao = "Conexão T2/T3";*/}
            {/*                    //                    break;*/}
            {/*                    //                case 8:*/}
            {/*                    //                    nomeCanalInspecao = "Internacional 3";*/}
            {/*                    //                    break;*/}
            {/*                    //                case 9:*/}
            {/*                    //                    nomeCanalInspecao = "Conexão T3";*/}
            {/*                    //                    break;*/}
            {/*                    //                default:*/}
            {/*                    //                    nomeCanalInspecao = "Desconhecido";*/}
            {/*                    //            }*/}
            {/*                    //            return (*/}
            {/*                    //                <tr key={index}>*/}
            {/*                    //                    <th>{nomeCanalInspecao}</th>*/}
            {/*                    //                    <td>{status}</td>*/}
            {/*                    //                    <td>{monRaiox.lastPostString}</td>*/}
            {/*                    //                </tr>*/}
            {/*                    //            );*/}
            {/*                    //        })}*/}
            {/*                    //    </div>*/}
            {/*                    //</div>*/}



            {/*                    <table className="table col-12 table-responsive">*/}
            {/*                        <thead className="table-dark">*/}
            {/*                            <tr>*/}
            {/*                                <th scope="col">Canal de Inspeção</th>*/}
            {/*                                <th scope="col">Status</th>*/}
            {/*                                <th scope="col">Última Atualização</th>*/}
            {/*                            </tr>*/}
            {/*                        </thead>*/}
            {/*                        <tbody>*/}
            {/*                            {allModules.map((modulo, index) => {*/}
            {/*                                // Desestrutura os valores de monRaiox e monPortico*/}
            {/*                                const { monRaiox, monPortico } = modulo;*/}
            {/*                                console.log(modulo)*/}

            {/*                                let status;*/}
            {/*                                switch (monRaiox.online) {*/}
            {/*                                    case true: status = "Online";*/}
            {/*                                        break;*/}
            {/*                                    case false: status = "Offline";*/}
            {/*                                        break;                */}
            {/*                                }*/}

            {/*                                // Define o nome do canal de inspeção com base em monRaiox.idSetor*/}
            {/*                                let nomeCanalInspecao;*/}
            {/*                                switch (monRaiox.idSetor) {*/}
            {/*                                    case 4:*/}
            {/*                                        nomeCanalInspecao = "Doméstico 1";*/}
            {/*                                        break;*/}
            {/*                                    case 5:*/}
            {/*                                        nomeCanalInspecao = "Doméstico 2";*/}
            {/*                                        break;*/}
            {/*                                    case 6:*/}
            {/*                                        nomeCanalInspecao = "Internacional 2";*/}
            {/*                                        break;*/}
            {/*                                    case 7:*/}
            {/*                                        nomeCanalInspecao = "Conexão T2/T3";*/}
            {/*                                        break;*/}
            {/*                                    case 8:*/}
            {/*                                        nomeCanalInspecao = "Internacional 3";*/}
            {/*                                        break;*/}
            {/*                                    case 9:*/}
            {/*                                        nomeCanalInspecao = "Conexão T3";*/}
            {/*                                        break;*/}
            {/*                                    default:*/}
            {/*                                        nomeCanalInspecao = "Desconhecido";*/}
            {/*                                }*/}


            {/*                                return (*/}
            {/*                                    <tr key={index} style={{backgroundColor: 'lightgray', marginBottom: '4px'}}>*/}
            {/*                                        <td className="linhasTabela">{nomeCanalInspecao}</td>*/}
            {/*                                        <td>{status}</td>*/}
            {/*                                        <td className="linhasTabela" style={{ overflowX: 'auto' }}>{monRaiox.lastPostString}</td>*/}
            {/*                                    </tr>*/}
            {/*                                );*/}
            {/*                            })}*/}
            {/*                        </tbody>*/}
            {/*                    </table>*/}
            {/*                );*/}
            {/*            })()*/}
            {/*        ) : (*/}
            {/*            <strong>Nenhum equipamento encontrado 2</strong>*/}
            {/*        )*/}
            {/*    ) : (*/}
            {/*        <p>Nenhum equipamento encontrado 1</p>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="list-modulosOnline container col-12">
                {state.listItensSetor ? (
                    state.listItensSetor.length > 0 ? (
                        (() => {
                            const allModules = state.listItensSetor.flatMap((setor) => setor.listModuloRxPrt);


                            const primeiraLista = allModules.slice(0, 4);
                            const segundaLista = allModules.slice(4, 20);
                            const terceiraLista = allModules.slice(20, 24);
                            const quartaLista = allModules.slice(24, 26);
                            const quintaLista = allModules.slice(26, 36);
                            const sextaLista = allModules.slice(36, 40);

                            console.log(allModules)

                            const renderImages = (modules) =>
                                modules.map((modulo) => {
                                    const { idModulo, monRaiox, monPortico } = modulo;
                                    //const temRaiox = monRaiox !== null;
                                    //const temPortico = monPortico !== null;
                                    //const RaioxLadoD = temRaiox && monRaiox.lado === "D";
                                    //const PorticoLadoD = temPortico && monPortico.lado === "D";
                                    //let auxRaioxLogado = monRaiox.rxLogado;
                                    //let auxRaioxLigado = monRaiox.rxLigado;
                                    //let auxRaioxEnerg = monRaiox.rxEnergizado;

                                    return (
                                        <React.Fragment key={idModulo}>
                                                <div className={`modulo-container`}>
                                                    <div className="idModulo__content">
                                                        <p className="paragraph paragraph--sm text"><strong>{idModulo}</strong></p>
                                                    </div>
                                                    <img
                                                        src={Modulo}
                                                        alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                        className="modulo-img"
                                                    />
                                                    <div className="contentPassager">
                                                        {/*<div className="passagerProcess">*/}
                                                        {/*    <p className="paragraph paragraph--sm text">95</p>*/}
                                                        {/*</div>*/}
                                                    {monRaiox.online == 0 ? (
                                                        <div className="moduloOffline">
                                                            <p className="paragraph paragraph--sm text">Offline</p>
                                                        </div>
                                                    ) : (
                                                        <div className="moduloOnline">
                                                            <p className="paragraph paragraph--sm text">Online</p>
                                                        </div>
                                                    )}
                                                    </div>
                                                    {/* <div className="tooltip">
                                                        <p className="paragraph paragraph--sm text">Infos</p>
                                                        <span className="tooltiptext">{monRaiox.lastPostString}</span>
                                                    </div> */}
                                                    {/* <p className="paragraph paragraph--sm text">{monRaiox.lastPostString}</p> */}
                                                </div>
                                        </React.Fragment>
                                    );
                                });

                            return (
                                <React.Fragment>
                                    <div className="modulos__content">
                                        {renderImages(primeiraLista)}
                                    </div>
                                    {segundaLista.length > 0 && (
                                        <div className="modulos__content">
                                            {renderImages(segundaLista)}
                                        </div>
                                    )}
                                    {terceiraLista.length > 0 && (
                                        <div className="modulos__content">
                                            {renderImages(terceiraLista)}
                                        </div>
                                    )}
                                    {quartaLista.length > 0 && (
                                        <div className="modulos__content">
                                            {renderImages(quartaLista)}
                                        </div>
                                    )}
                                    {quintaLista.length > 0 && (
                                        <div className="modulos__content">
                                            {renderImages(quintaLista)}
                                        </div>
                                    )}
                                    {sextaLista.length > 0 && (
                                        <div className="modulos__content" style={{ marginBottom: '50px' }}>
                                            {renderImages(sextaLista)}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })()
                    ) : (
                        <strong>Selecione um Canal de Inspecao para visualizar os equipamentos</strong>
                    )
                ) : (
                    <p>Nenhum equipamento encontrado</p>
                )}
            </div>

        </BaseLayout>
    );
};
export default PageModulosOnline;
