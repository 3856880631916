import { configureStore } from "@reduxjs/toolkit"
import sliceLogin from "./features/Login/slices"
import sliceAtivarAcesso from "./features/login-ativar/slices"
import sliceForgotPass from "./features/login-forgot/slices"
import sliceGetPontosMonitor from "./features/PontosMonitor/slices";
import sliceGetGrupoSetor from "./features/grupo-de-setor/slices";
import sliceGetGradeBySetor from "./features/get-grade/slices";
import sliceUpdateGradeBySetor from "./features/update-grade/slices";
import sliceGetRptLogRaiox from "./features/relatorios/rptLogRaiox/slices";
import sliceGetRptLogSistema from "./features/relatorios/rptLogSistema/slices";
import listaUsuariosSlice from "./features/usuarios-lista/slices";
import registroUsuarioSlice from "./features/usuarios-registro/slices"
import sliceGetRptLogPortico from "./features/relatorios/rptLogPortico/slices"
import sliceGetRptGrafLogPortico from "./features/relatorios/rptGrafPortico/slices"
import { updateGradeBySetor } from "./features/update-grade/services"
import { getRptLogRaiox } from "./features/relatorios/rptLogRaiox/services"
import { getRptLogSistema } from "./features/relatorios/rptLogSistema/services"
import { getRptGrafLogPortico } from "./features/relatorios/rptGrafPortico/services"
import listaUsuarios from "./features/usuarios-lista/services"
//import slicePostHorariosGrade from "./features/insert-grade";


export const store = configureStore({
  reducer: {
    /*CANAL DE INSPECAO*/
    getPontosMonitor: sliceGetPontosMonitor,
    getLogin: sliceLogin,
    getAtivarAcesso: sliceAtivarAcesso,
    getForgotPass: sliceForgotPass,
    getGrupoSetor: sliceGetGrupoSetor,
    getGradeBySetor: sliceGetGradeBySetor,
    updateGradeBySetor: sliceUpdateGradeBySetor,
    getRptLogRaiox: sliceGetRptLogRaiox,
    getRptLogPortico: sliceGetRptLogPortico,
    getRptLogSistema: sliceGetRptLogSistema,
    getRptGrafLogPortico: sliceGetRptGrafLogPortico,
    listaUsuarios: listaUsuariosSlice,
    registroUsuario: registroUsuarioSlice
    //postHoraGrade: slicePostHorariosGrade
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
