import { useContext } from "react"
import { ContextMenu } from "../menu"
import { getUser, logout } from "../../../helpers/user"
import "./styles.scss"
import Button from "../../Button"
import BreadCrumb from "../BreadCrumb";

const Header = () => {
  const context = useContext(ContextMenu)
  //const { customCliente: { imgFaixa, nomeFantasia, primaryColor } } = getUser()
  const { usuario } = getUser();

  return (
    <header className="main-header">
      <section className="col-1">
        <button className="main-header__btn-toggle" onClick={() => context.setMenuIsOpen()}>
          <i className="fa-solid fa-bars"></i>
        </button>
        <div className="main-header__brand">
          {/*<img src={imgFaixa} alt={nomeFantasia} />*/}
        </div>
      </section>

      {/*<div className="col-sm-12 col-md-7">*/}
      {/*  <BreadCrumb />*/}
      {/*</div>*/}

      <nav className="col-2">
        <div className="user-avatar">
          {/*<div className="user-name">*/}
          {/*  <i className="fa-solid fa-user"></i>*/}
          {/*  <p className="paragraph paragraph--sm text">{usuario.nome}</p>*/}
          {/*</div>*/}
          <Button variant="danger" size="sm" onClick={() => logout()}>sair</Button>
        </div>
      </nav>
    </header>
  )
}

export default Header