import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import download from "../../../helpers/download";
import { getRptGrafLogPortico, IRequestGetRptGrafLogPortico } from "./services";

const initialState: any = {
  status: "",
  noContent: true
};

export const actionGetRptGrafLogPortico: any = createAsyncThunk<any, IRequestGetRptGrafLogPortico>(
  "ACTION_GET_RPT_GRAF_LOG_PORTICO",
  async (data) => {
    const result = await getRptGrafLogPortico(data);

    download(result, { ext: data.reportType, fileName: `relatorio-grafico-portico` });

    return result;
  }
);

const sliceGetRptGrafLogPortico = createSlice({
  name: "GET_RPT_GRAF_LOG_PORTICO",
  initialState,
  reducers: {},
  extraReducers: {
    [actionGetRptGrafLogPortico.pending]: (state) => {
      state.status = "loading";
    },
    [actionGetRptGrafLogPortico.fulfilled]: (state, { payload: { status } }) => {
      state.status = "success";
      state.noContent = status === 204;
    },
    [actionGetRptGrafLogPortico.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default sliceGetRptGrafLogPortico.reducer;
