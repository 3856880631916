import { TSetor } from "../features/types";

type TOption = { value: string; children: string };

interface IFactoryFields {
  grupoMonitoria: Array<TOption>,
  typesReport: Array<TOption>,
  intervaloRptPortico: Array<TOption>,
  reportsByLoja: Array<TOption>,
  reportsBySetor: Array<TOption>,
  niveisDeAcesso: Array<TOption>,
  grupoMonitoramento: (data: Array<any>) => Array<TOption> // TODO: Type para grupo monitoramento
  grupoSetor: (data: Array<TSetor>) => Array<TOption> // TODO: Type para grupo de setores
}

const factoryFieldsByApi = function (
  data: Array<any>,
  { value, children }: TOption,
  initialSelect: string = "selecione"
) {
  const createOptions = data.map((item: any) => ({
    value: item[value],
    children: item[children],
  }));
  
  createOptions.unshift({ value: "", children: initialSelect })
  return createOptions;
};

//const factoryFieldsCentral = (data) => {
//  if(!data) return
//  const createOptions = data.map(({ id, modelo, versao }: TCentral) => ({
//    value: id,
//    children: `Modelo: ${modelo} | ID: ${id} | Versão: ${versao}`,
//  }));
  
//  createOptions.unshift({ value: "", children: "Selecione uma central" })
//  return createOptions
//}

const grupoMonitoria = [
  { value: "0", children: "Selecione o tipo" },
  { value: "1", children: "Incêndio" },
  { value: "2", children: "Ativos Criticos" },
];

const typesReport = [
  { value: "", children: "Selecione o tipo de arquivo" },
  { value: "pdf", children: "pdf" },
  { value: "word", children: "word" },
  { value: "xls", children: "xls" },
]

const intervaloRptPortico = [
    { value: "", children: "Selecione o intervalo /min" },
    { value: "5", children: "5" },
    { value: "10", children: "10" },
    { value: "30", children: "30" },
    { value: "60", children: "60" }
]

const reportsByLoja = [
  { value: "", children: "Selecione" },
  { value: "RptPtosPanorama", children: "Panorama Atual dos Pontos de Monitoramento" },
  { value: "RptPtosConfig", children: "Configuração dos Pontos de Monitoramento" },
  { value: "RptDispositivos", children: "Dispositivos de Monitoramento Instalados" },
  { value: "RptHistoricosDispositivos", children: "Historico de conexão dos dispositivos" },
];

const reportsBySetor = [
    { value: "", children: "Selecione" },
    { value: "RptLogRaiox", children: "Logs do Raio-X" },
    { value: "RptLogSistema", children: "Logs do Sistema" },
    { value: "RptLogPortico", children: "Logs do Pórtico" },
    { value: "RptGrafLogPortico", children: "Gráfico de Demandas/Pórticos" }
];

export const mapStatus: any = {
  false: "Inativo",
  true: "Ativo"
}

export const mapNiveisDeAcesso: any = {
  //"3": "Usuário Loja",
  //"4": "Manutenção Loja",
  //"5": "Manutenção Regional",
  //"6": "Gerência Manutenção Regionais",
  "7": "Gestão"
}

const niveisDeAcesso = [
  { value: "0", children: "selecione uma opção" },
  //{ value: "3", children: "Usuário Loja" },
  //{ value: "4", children: "Manutenção Loja" },
  //{ value: "5", children: "Manutenção Regional" },
  //{ value: "6", children: "Gerência Manutenção Regionais" },
  { value: "7", children: "Gestão" },
]

const unidadesDeMedida = (data: Array<string>) => factoryFieldsByApi(data, { value: "unidade", children: "unidade" }, "escolha a unidade");
const grupoMonitoramento = (data: Array<any>) => factoryFieldsByApi(data, { value: "id", children: "nome" })
const grupoSetor = (data: Array<TSetor>) => factoryFieldsByApi(data, {value: "id", children: "nome"})

const factoryFields: IFactoryFields = {
  grupoSetor,
  grupoMonitoria,
  typesReport,
  intervaloRptPortico,
  reportsByLoja,
  reportsBySetor,
  grupoMonitoramento,
  niveisDeAcesso
}

export default factoryFields;
