/* eslint-disable react/style-prop-object */
import React from "react";
import "./_styles.scss";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import RaioxLigado from "../../assets/RaioxLigado.png";
import RaioxLogado from "../../assets/RaioxLogado.png";
import RaioxEnerg from "../../assets/RaioxEnerg.png";
import RaioxLigadoRotate from "../../assets/RaioxLigadoRotate.png";
import RaioxLogadoRotate from "../../assets/RaioxLogadoRotate.png";
import RaioxEnergRotate from "../../assets/RaioxEnergRotate.png";
import RaioxImg from "../../assets/raiox.png";
import Portico from "../../assets/Porticosm.png";
import BaseLayout from "../../components/Layout";
import FilterSetorMonitora from "../../components/FilterSetoresMonitora";
import { actionGetPontosMonitor } from "../../features/PontosMonitor/slices";
import Select from "../../components/Select";
import Input from "../../components/Inputs";
import Button from "../../components/Button";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getUser } from "../../helpers/user";
import Progress from "../../components/Progress";

const initialState = {
    visible: false,
    filter: null,
    listItensSetor: [],
} as any;

let responseSocket;

const contador = {
    num: 0,
}

const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SOCKET}/hubPtosMonitor`, {
        accessTokenFactory: () => getUser().token,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

const Actions = ({ handleCancelClick }) => {
    return (
        <div style={{ display: 'flex' }}>
            <Button size="sm" variant="danger" onClick={handleCancelClick}>
                Cancelar
            </Button>
        </div>
    );
};

const PageDetalhamento = () => {    

    const [inputsDisabled, setInputsDisabled] = React.useState(true);
    const [showCancelButton, setShowCancelButton] = React.useState(false);

    const dispatch = useAppDispatch();    

    const { getPontosMonitor } = useAppSelector((state) => state)
            
    const [state, setState] = React.useState(initialState)
    
    /*const getSetorSelected = (filter) => setState({ ...state, filter })*/
    const getSetorSelected = (filter) => {
        console.log(filter)
        if (filter === null || filter === 0) {
            setState({ ...state, filter: null, listItensSetor: [] });
        } else {
            setState({ ...state, filter });
        }
    };
    
    const createList = (data) => {
        if (!data || !state.filter) return;

        const filtered = data.filter(({ id }) => id === state.filter);
        const listItensSetor = filtered;

        setState({
            ...state,
            listItensSetor,
        });
    };

    React.useEffect(() => {
        //testar dispatch
        dispatch(actionGetPontosMonitor())
        connection.start().then(() => console.log('connection started aqui'));
    }, []);

    React.useEffect(() => {

        connection.on('SocketPtosMonitor', function (data) {
            responseSocket = data
            contador.num++
            if (contador.num > 1000) {
                contador.num = 1
            }
            //console.log(contador)
            createList(responseSocket.setores)            
        });

        if (responseSocket) {
            console.log(responseSocket.setores)
            createList(responseSocket.setores)
            console.log("opcao1")
        } else {
            console.log(getPontosMonitor.data)
            createList(getPontosMonitor.data.setores)
            console.log("opcao2")
        }
        
    }, [getPontosMonitor.data, state.filter, contador.num]);

    //const visibilidade = () => {
    //    setState({ ...state, visible:true });
    //}

    const handleUpdateClick = () => {
        setState({ ...state, visible: true });
        setInputsDisabled(false);
        setShowCancelButton(true);
        if (connection) {
            connection.stop()
                .then(() => console.log('Socket desconectado'))
                .catch((err) => console.error('Erro ao desconectar o socket', err));
        }
    }

    const handleCancelClick = () => {
        //dispatch(actionGetGradeBySetor(state.filter));
        setState({ ...state, visible: false });
        setInputsDisabled(true);
        setShowCancelButton(false);
        connection.start().then(() => console.log('connection started novamente'));
    }

    return (
        <BaseLayout>
            <div className="container" style={{marginBottom: '20px'}}>
                <div className="row headerPage">
                    <div className="col-lg-4 col-md-6 co-sm-12 mb-20">
                        <FilterSetorMonitora cb={getSetorSelected} />
                    </div>
                    
                    {/* {state.filter != null && (
                        <React.Fragment>
                            <Input
                                type="datetime-local"
                                size="sm"
                                label="Data Início"
                                name="dataInicio"
                            //onChange={handlerChange}
                            />
                            <Input
                                type="datetime-local"
                                size="sm"
                                label="Data Fim"
                                name="dataFim"
                            //onChange={handlerChange}
                            />
                            {showCancelButton && (
                                <Actions
                                    handleCancelClick={handleCancelClick}
                                />
                            )}
                            <Button size="sm" onClick={handleUpdateClick}>
                                Buscar dados do período
                            </Button>
                        </React.Fragment>
                    )} */}
                    {/* {state.filter != null && (
                        <div className="legenda__content col-md-12 col-sm-12">
                            <div className="itensLegenda__content">
                                <div className="item ">
                                    <img
                                        src={RaioxEnergRotate}
                                        alt={`Raio-X`}
                                        className="contentImage"
                                    />
                                    <p className="paragraph paragraph--sm text color-secondary">Desenergizado</p>
                                </div>
                                <div className="item">
                                    <img
                                        src={RaioxLigadoRotate}
                                        alt={`Raio-X`}
                                        className="contentImage"
                                    />
                                    <p className="paragraph paragraph--sm text color-secondary">Sem Operador</p>
                                </div>
                                <div className="item">
                                    <img
                                        src={RaioxLogadoRotate}
                                        alt={`Raio-X`}
                                        className="contentImage"
                                    />
                                    <p className="paragraph paragraph--sm text color-secondary">Em Operacao</p>
                                </div>
                            </div>
                        </div>
                    )} */}
                    {/* {state.visible == true && (
                        <div className="legenda__content col-md-12 col-sm-12">
                            <div className="itensLegenda__content">
                                <div className="item ">
                                    <div className="itemRaioxTime"></div>
                                    <p className="paragraph paragraph--sm text color-secondary">Tempo de Operacao</p>
                                </div>
                                <div className="item">
                                    <div className="itemPassagerProcess"></div>
                                    <p className="paragraph paragraph--sm text color-secondary">Passageiros Processados</p>
                                </div>
                                <div className="item">
                                    <div className="itemPassagerAlerts"></div>
                                    <p className="paragraph paragraph--sm text color-secondary">Quantidade de Alarmes</p>
                                </div>
                                <div className="item">
                                    <div className="itemPassagerRandom"></div>
                                    <p className="paragraph paragraph--sm text color-secondary">Quantidade de Aleatórios</p>
                                </div>
                            </div>
                        </div>
                    )} */}
                    
                </div>
            </div>
            
            <div className="list-detahamento container col-12">
                {state.listItensSetor ? (
                    state.listItensSetor.length > 0 ? (
                        (() => {
                            const allModules = state.listItensSetor.flatMap((setor) => setor.listModuloRxPrt);


                            const firstRowModules = allModules.slice(0, 10);
                            const secondRowModules = allModules.slice(10);

                            console.log(allModules)

                            const renderImages = (modules) =>
                                modules.map((modulo) => {
                                    const { idModulo, monRaiox, monPortico } = modulo;
                                    const temRaiox = monRaiox !== null;
                                    const temPortico = monPortico !== null;
                                    const RaioxLadoD = temRaiox && monRaiox.lado === "D";
                                    const PorticoLadoD = temPortico && monPortico.lado === "D";
                                    let auxRaioxLogado = monRaiox.rxLogado;
                                    let auxRaioxLigado = monRaiox.rxLigado;
                                    let auxRaioxEnerg = monRaiox.rxEnergizado;

                                    return (
                                        <React.Fragment key={idModulo}>
                                            {!RaioxLadoD && temRaiox && (
                                                <div className={`equipamento-container`}>
                                                    {state.visible == true && (
                                                        <div className="raioxTime">
                                                            <p className="paragraph paragraph--sm text">28min</p>
                                                        </div>
                                                    )}
                                                    {auxRaioxLogado == true ? (
                                                        <img
                                                            src={RaioxLogado}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : auxRaioxEnerg == true && auxRaioxLogado == false && auxRaioxLigado == true ? (
                                                        <img
                                                            src={RaioxLigado}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : auxRaioxEnerg == false ? (
                                                        <img
                                                            src={RaioxEnerg}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={RaioxEnerg}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    )}
                                                    {state.visible == true && (
                                                        <p className="paragraph paragraph--sm text color-secondary">‎ </p>
                                                    )}
                                                </div>
                                            )}

                                            {!PorticoLadoD && temPortico && (
                                                <div className={`equipamento-container`}>
                                                    {state.visible == true && (
                                                        <p className="paragraph paragraph--sm text color-secondary" style={{ marginBottom: '6px' }}>‎ </p>
                                                    )}
                                                    <img
                                                        src={Portico}
                                                        alt={`Pórtico ${monPortico.equipamento.nome}`}
                                                        className="equipamento-img"
                                                    />
                                                    {state.visible == true && (
                                                        <div className="contentPassager">
                                                            <div className="passagerProcess">
                                                                <p className="paragraph paragraph--sm text">95</p>
                                                            </div>
                                                            <div className="passagerAlerts">
                                                                <p className="paragraph paragraph--sm text">18</p>
                                                            </div>
                                                            <div className="passagerRandom">
                                                                <p className="paragraph paragraph--sm text">30</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {RaioxLadoD && (
                                                <div className={`equipamento-container`}>
                                                    {state.visible == true && (
                                                        <div className="raioxTime">
                                                            <p className="paragraph paragraph--sm text">28min</p>
                                                        </div>
                                                    )}
                                                    {auxRaioxLogado == true ? (
                                                        <img
                                                            src={RaioxLogado}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : auxRaioxEnerg == true && auxRaioxLogado == false && auxRaioxLigado == true ? (
                                                        <img
                                                            src={RaioxLigado}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : auxRaioxEnerg == false ? (
                                                        <img
                                                            src={RaioxEnerg}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={RaioxEnerg}
                                                            alt={`Raio-X ${monRaiox.equipamento.nome}`}
                                                            className="equipamento-img"
                                                        />
                                                    )}
                                                    {state.visible == true && (
                                                        <p className="paragraph paragraph--sm text color-secondary">‎ </p>
                                                    )}
                                                </div>
                                            )}

                                            {PorticoLadoD && (
                                                <div className={`equipamento-container`}>
                                                    {state.visible == true && (
                                                        <p className="paragraph paragraph--sm text color-secondary" style={{ marginBottom: '6px' }}>‎ </p>
                                                    )}
                                                    <img
                                                        src={Portico}
                                                        alt={`Pórtico ${monPortico.equipamento.nome} Lado D`}
                                                        className="equipamento-img"
                                                    />
                                                    {state.visible == true && (
                                                        <div className="contentPassager">
                                                            <div className="passagerProcess">
                                                                <p className="paragraph paragraph--sm text">95</p>
                                                            </div>
                                                            <div className="passagerAlerts">
                                                                <p className="paragraph paragraph--sm text">18</p>
                                                            </div>
                                                            <div className="passagerRandom">
                                                                <p className="paragraph paragraph--sm text">30</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                });

                            return (
                                <React.Fragment>
                                    <div className="equipamentos__content">
                                        {renderImages(firstRowModules)}
                                    </div>
                                    {secondRowModules.length > 0 && (
                                        <div className="equipamentos__content" style={{marginBottom: '50px'}}>
                                            {renderImages(secondRowModules)}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })()
                    ) : (
                        <strong>Selecione um Canal de Inspecao para visualizar os equipamentos</strong>
                    )
                ) : (
                    <p>Nenhum equipamento encontrado</p>
                )}
            </div>
        </BaseLayout>
    );
};
export default PageDetalhamento;
